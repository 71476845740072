import { isValidPhoneNumber, AsYouType } from 'libphonenumber-js';
import queryString from 'query-string';
import moment from 'moment';

export const isBrowser = typeof window !== `undefined`;

export function validatePhone(value) {
  let isValid = isValidPhoneNumber(value || '', 'US');
  if (!isValid) {
    isValid = isValidPhoneNumber(value || '', 'CA');
  }
  return isValid;
}

export function formatPhoneFromServer(value) {
  if (!value) {
    return null;
  }

  const asYouType = new AsYouType('US');
  return asYouType.input(value.replace('+1', ''));
}


export function dateLeadingZero(num) {
  const absNum = Math.abs(num);
  if (absNum < 10) {
    return `0${absNum}`;
  }
  return absNum;
}

export function formatBirthdayFromServer(day, month, year) {
  if (!day || !month || !year) {
    return null;
  }
  return `${dateLeadingZero(month)}/${dateLeadingZero(day)}/${year}`;
}

// See: https://github.com/iamcal/lib_timezones/blob/master/lib/lib_timezones.js
export function timezoneOptions() {
  return [
    {
      value: '',
      label: 'Select...',
    },
    {
      value: 'America/New_York',
      label: 'Eastern Time (US)',
    },
    {
      value: 'America/Chicago',
      label: 'Central Time (US)',
    },
    {
      value: 'America/Denver',
      label: 'Mountain Time (US)',
    },
    {
      value: 'America/Los_Angeles',
      label: 'Pacific Time (US)',
    },
    {
      value: 'America/Phoenix',
      label: 'Arizona',
    },
    {
      value: 'America/Anchorage',
      label: 'Alaska',
    },
    {
      value: 'Pacific/Honolulu',
      label: 'Hawaii',
    },
    {
      value: 'Asia/Tokyo',
      label: 'Japan Standard Time',
    },
  ];
}

export function timezonePrettyName(tz) {
  const timezones = timezoneOptions();
  for (let i = 0; i < timezones.length; i++) {
    if (tz === timezones[i].value) {
      return timezones[i].label;
    }
  }
  return tz;
}


const states = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  // 'AS': 'American Samoa',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  // 'FM': 'Federated States Of Micronesia',
  'FL': 'Florida',
  'GA': 'Georgia',
  // 'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  // 'MH': 'Marshall Islands',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  // 'MP': 'Northern Mariana Islands',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  // 'PW': 'Palau',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  // 'VI': 'Virgin Islands',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming',

  // ONLY ALLOW US FOR NOW!!

  // 'YT': 'Yukon Territory',
  // 'AE': 'AE - Armed Forces Africa \ Canada \ Europe \ Middle East',
  // 'AA': 'AA - Armed Forces America (Except Canada)',
  // 'AP': 'AP - Armed Forces Pacific',

  'BC': 'British Columbia',
  'ON': 'Ontario',
  'NS': 'Nova Scotia',
  'PE': 'Prince Edward Island',
  'NB': 'New Brunswick',
  'NL': 'Newfoundland',
  'QC': 'Quebec',
  'MB': 'Manitoba',
  'SK': 'Saskatchewan',
  'AB': 'Alberta',
  'NT': 'Northwest Territories',
  'YT': 'Yukon Territory',

};

export function statesOptions(hideSelect) {
  const keys = Object.keys(states);
  const ret = hideSelect ? [] : [{
    value: '',
    label: 'Select...',
  }];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    ret.push({
      value: key,
      label: states[key],
    });
  }
  return ret;
}

export function statePrettyName(abbr) {
  return states[abbr];
}

export function orderTeamsByLanes(product, teams) {
  if (!product || !product.meta || !product.meta.lanes || !teams ||!teams.length) {
    return teams;
  }
  const lanes = product.meta.lanes;
  return teams.sort((a, b) => {
    const aVal = lanes[`team_${a.id}`] || 10000;
    const bVal = lanes[`team_${b.id}`] || 10000;
    return aVal - bVal;
  });
}


export function capFirstLetter(str) {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Format a DAY date, stripping timezone
export function getMomentFromServerDate(d) {
  // const parts = d.split('T');
  // return moment(parts[0]);
  return moment(d);
}

export function getMomentFromServerDay(d) {
  const parts = d.split('T');
  return moment(parts[0]);
}

export function formatDay(d, format) {
  // console.log(d, d.length, 'd and length');
  const date = getMomentFromServerDay(d);
  return date.format(format);
}
// Format a DAY date, stripping timezone
export function formatDateTime(d, format = 'M/D/Y h:mma') {
  const date = getMomentFromServerDate(d);
  return date.format(format);
}

export function truncateString(str, length) {
  if (!str) {
    return str;
  }
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
}

export function roundLikePhp(num, dec) {
  const num_sign = num >= 0 ? 1 : -1;
  return parseFloat((Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec));
}

// Function to take an input ref and copy to clipboard it's contents
export function copyToClipboard(ref) {
  if (ref && ref.current) {
    ref.current.select();
    document.execCommand('copy');
    ref.current.setSelectionRange(0, 0);
    ref.current.blur();
    return true;
  }
  return false;
}

// Currently only handles youtube and vimeo
export function formatVideoEmbedLink(video) {
  if (!video) {
    return null;
  }
  // Parse the URL and grab the video id, unless it already contains embed
  if (video.includes('embed') || video.includes('vimeo.com')) {
    return video;
  }
  const parsed = queryString.parseUrl(video);
  const { query } = parsed || {};
  const { v } = query || {};
  if (v) {
    return `https://www.youtube.com/embed/${v}`;
  }
  return null;
}

export function pluralize(str, num, plural) {
  const absNum = Math.abs(num);
  if (absNum === 1) {
    return str;
  }
  return plural || `${str}s`;
}


export function ucwords(str) {
  return str.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
  	function(s){
  	  return s.toUpperCase();
	});
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
// Find the index of an item by it's id
export function findIndexById(items, id, idKey = 'id') {
  if (!items || !items.length || !id) {
    return -1;
  }
  let ret = -1;
  items.forEach((item, i) => {
    if (item && item[idKey] === id) {
      ret = i;
    }
  });
  return ret;
}

