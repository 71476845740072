import React from 'react'
import { Helmet } from 'react-helmet';

function SEO({ description, lang, meta, title }) {
  const metaDescription = description || 'Sign up for fun bowling leagues across the country!';
  return (
    <Helmet
      htmlAttributes={{
        lang: lang || 'en',
      }}
      title={title}
      titleTemplate={`%s | Fun Bowling Leagues`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'BBBi',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'facebook-domain-verification',
          content: 'iz21pomrmyshwh2427oyb8otscf2xm',
        },
      ].concat(meta || [])}
    >
      <script type="text/javascript">{`
        var head = document.head;
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = "https://t.funbowlingleagues.com/v1/lst/universal-script?ph=e4af0ed314caac171543dde8c1a6f3365c03c97d611d89a74a4e20dc4a40cbbb&tag=!clicked";
        head.appendChild(script);
      `}</script>
      <script type="text/javascript">{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M7VL94H');
      `}</script>
      <noscript>{`
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M7VL94H"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `}</noscript>
    </Helmet>
  );
}

export default SEO
